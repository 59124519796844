@import '../variables.css';

.optionalParameterGroupContainer {
    white-space: normal;
    margin-top: 6px;
}

.paddedLabel {
    display: block;
    margin: 0;
    text-align: left;
    font-size: 1rem;
    white-space: normal;
}

.padRight {
    margin-right: 6px;
}

.input___numeric {
    width: calc(98% - 5.5rem);
    text-align: right;
    margin: 3.5px 1% 3.5px 0%;
}

.input___groupedNumeric {
    width: 4.5rem;
    text-align: right;
    margin: 3.5px 0.1rem;
}

.noUnits {
    width: 100%;
}

.invalid {
    border-color: var(--border-colour-error);
    color: var(--text-colour-error);
}

.selector {
    padding: calc(0.4rem - 2px); /* So that height is same as an associated input (which, with 0.4rem padding, is 4px shorter) */
}

.selector___detent {
    width: 100%;
    text-align: left;
    margin: 3.5px 1% 3.5px 0%;
}

.selector___groupedDetent {
    text-align: left;
    margin: 0 1% 7px 0%;
}

.selector___inputUnit {
    width: 5.5rem;
    margin-left: 1%;
}

.selector___groupedUnit {
    margin-left: 3px;
}

.selector___outputUnit {
    width: 5.5rem;
}

.checkbox {
    width: 2rem;
    height: 2rem;
    margin: 3.5px 0;
}

.groupedCheckbox {
    width: 2rem;
    height: 2rem;
    margin: 0 0.6rem 0 0;
    display: inline-block;
    vertical-align: middle;
}

.error {
    color: var(--text-colour-error);
}

.outputResult {
    margin-left: auto;
}

.outputContainer {
    margin: 2px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.numericOutput {
    font-size: 1rem;
    margin: 0 6px 0 0;
}

.unitValueContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}