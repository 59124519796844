@import '../variables.css';

.LeasingPerformanceCategory {
    flex: 1;
    min-width: 200px;
    padding: 1rem;
    background-color: var(--item-background);
    border: 1px solid var(--border-colour);
    white-space: normal;
    overflow-x: hidden;
}

.operationList {
    list-style-type: none;
}

.operationList li {
    font-size: var(--font-size-small);
    white-space: normal;
}

@media only screen and (min-width: 768px) {
    .LeasingPerformanceCategory {
        min-width: 300px;
    }
}