@import '../variables.css';

.LandingPage {
    font-family: var(--font-family-landing-page);
    font-weight: bold;
    background-color: var(--background-base);
    height: 100%-10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    padding: 1rem;
}

.logoContainer {
    width: 100%;
    padding: 1.5rem;
    margin: 1rem 0;
}

.logo {
    margin: 0 auto;
}

.bannerContainer {
    width: 100%;
    margin: 0 auto;
    padding: 120px 0;
    background-color: black;
    background-image: url("../static/img/slinsgby.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: var(--palette-mid-light);
}

.titleContainer {
    font-size: var(--font-size-max);
    color: var(--text-colour-application-title);
    padding: 1rem 0 2.5rem 0;
    text-align: center;
}

.body {
    position: relative;
    display: flex;
    justify-content: center;
    flex-flow: row nowrap;
    gap: 2%;
    width: 100%;
}

.body___item {
    width: 50%;
    padding: 2rem;
    border: 1px solid var(--background-accent);
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-position: center;
}

.body___item h2 {
    margin-bottom: 2rem;
    font-size: 2rem;
}

.body___from {
    background-color: var(--palette-mid-dark);
    background-image: url("../static/img/quill-pen-transparent-500x500.png");
    color: var(--palette-light);
    align-items: flex-start;
}

.body___to {
    background-color: var(--palette-mid-light);
    background-image: url("../static/img/machine-transparent-500x500.png");
    color: var(--palette-dark);
    align-items: flex-end;
}

.figure {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    margin-top: 1rem;
}

.figure figcaption {
    font-size: var(--font-size-large);
}

.icon {
    margin-right: 1rem;
    width: 100px;
    height: 100px;
}

.icon___inverted {
    fill: white;
}

.body___to .icon {
    margin-right: 0;
    margin-left: 1rem;
}

.callToActionButton {
    box-shadow: 6px 6px 10px var(--palette-dark);
    width: clamp(200px, 25%, 400px);
    padding: 0.7rem 0;
    font-size: var(--font-size-large);
    border-radius: 0.3rem;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}