@import '../variables.css';

.Feedback {
    width: 100%;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    margin-top: 1rem;
}

.statusMessage {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.selector {
    padding: 0.4rem 0.2rem;
}

.selector___placeholder {
    color: var(--text-colour-placeholder);
    font-style: italic;
    font-size: var(--font-size-small);
    padding: 0.5rem 0.1rem;
}

.textArea {
    font-family: var(--font-family-general);
    width: 100%;
    resize: vertical;
    min-height: 7rem;
}

.resizable {
    resize: vertical;
    overflow: auto;
}

.button {
    padding: 0.3rem 2rem;
}

.responseMessage {
    font-size: var(--font-size-small);
}

.error {
    color: var(--text-colour-error);
}

.messageHint {
    margin-top: calc(2px - 1rem);
    font-size: var(--font-size-extra-small);
    font-style: italic;
    margin-left: 1rem;
}