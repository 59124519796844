@import '../variables.css';

.LeasingAction {
    padding: 1rem 0 0 0;
}

.header {
    padding: 1rem;
    background-color: var(--item-background);
    border: 1px solid var(--border-colour);
    overflow-x: hidden;
}

.header p {
    white-space: normal;
}

.header___notLeased {
    display: flex;
    gap: 1rem;
    flex-wrap: nowrap;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.marginBefore {
    margin-top: 1rem;
}

.marginAfter {
    margin-bottom: 1rem;
}

.priceAndAction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    margin-left: 1rem;
}

.supportedOperations {
    margin-top: 2rem;
    white-space: normal;
}

.supportedOperations>h3 {
    margin-left: 0.5rem;
    margin-bottom: 0.2rem;
}

.categoryContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 1rem 0;
    border-top: 1px solid var(--border-colour);
}

.button {
    padding: 0.3rem 1rem;
}

.danger {
    color: var(--colour-danger);
    font-weight: bold;
}

.warning {
    color: var(--colour-warning);
    font-weight: bold;
}

.safe {
    color: var(--colour-safe);
}

@media only screen and (min-width: 768px) {
    .LeasingAction {
        padding: 2rem 1rem 1rem 1rem;
    }

    .priceAndAction {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }
}