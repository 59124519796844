@import '../variables.css';

.Expander {
    width: 100%;
}

.Expander___withContainer {
    border: 1px solid var(--border-colour);
    border-radius: 0.2rem;
}

.toggle {
    display: none;
}

.toggleLabel {
    display: block;
    font-weight: bold;
    font-size: var(--font-size-normal);
    text-align: left;
    white-space: normal;
    padding: 0.5rem 1rem;
    color: var(--text-colour-normal);
    background-image: var(--button-background-image);
    cursor: pointer;
    border-bottom: 1px solid var(--border-colour);
    border-radius: 0.2rem;
    transition: all 0.2s ease-out;
}

.toggleLabel:hover {
    background-image: var(--button-hover-background-image);
}

.toggleLabel::before {
    content: " ";
    display: inline-block;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
}

.toggle:checked+.toggleLabel::before {
    transform: rotate(90deg) translateX(-3px);
}

.contentContainer {
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.25s ease-in-out;
}

.contentContainer___withContainer {
    background-color: var(--item-background);
    border-radius: 0.2rem;
}

.toggle:checked+.toggleLabel+.contentContainer {
    max-height: 1000px;
}

.toggle:checked+.toggleLabel {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.content___withContainer {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
}

.notDisplayed {
    display: none;
}

.hidden {
    visibility: hidden;
}