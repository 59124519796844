.Unauthorised {
    width: 100%;
    flex-grow: 1;
    padding: 4rem;
    overflow-y: auto;
}

.heading,
.text {
    margin-bottom: 1rem;
    text-align: center;
}

.button {
    display: block;
    font-size: var(--font-size-large);
    padding: 0.5rem 1rem;
    width: clamp(200px, 25%, 400px);
    margin: 2rem auto;
}