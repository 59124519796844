@import '../variables.css';

.Leasing {
    width: 100%;
    padding: 1rem;
}

.carouselContainer {
    width: 100%;
    position: relative;
}

.messageContainer {
    margin: 3rem 0;
    white-space: normal;
    text-align: center;
    font-size: var(--font-size-extra-large);
}

.aircraftContainer {
    width: 100%;
}

.cartContainer {
    width: 100%;
}

.cartIconContainer {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;
}

.selectedAircraft {
    margin-top: 2rem;
}

.aircraftSummary {
    text-align: center;
    white-space: normal;
    margin-bottom: 1rem;
}

.manufacturer {
    font-size: var(--font-size-normal);
}

.description {
    font-size: var(--font-size-extra-large);
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}