@import '../variables.css';

.Hangar {
    width: 100%;
    padding: 1rem;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3rem 0;
}

.message {
    font-size: var(--font-size-extra-large);
}

.subMessage {
    font-size: var(--font-size-normal);
    margin-top: 0.5rem;
}

.selectedAircraft {
    position: relative;
    margin-top: 2rem;
    transition: margin 0.2s ease-in-out;
}

.selectedAircraft___collapsedHeader {
    margin-top: 1rem;
}

.aircraftSummary {
    text-align: center;
    margin-bottom: 1rem;
}

.manufacturer {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    font-size: var(--font-size-normal);
}

.description {
    width: 70%;
    text-align: center;
    margin: 0 auto;
    font-size: var(--font-size-extra-large);
    margin-bottom: 1rem;
}

.expandCollapseButton {
    position: absolute;
    right: 0;
}

.expandCollapseImage {
    display: block;
    width: 25px;
    height: 25px;
}

.nowrap {
    white-space: nowrap;
}

.fullWidth {
    width: 100%;
}

@media only screen and (min-width: 481px) {
    .expandCollapseImage {
        width: 50px;
        height: 50px;
    }
}