@import '../variables.css';

.button {
    font-family: var(--font-family-general);
    font-size: var(--font-size-normal);
    color: var(--text-colour-normal);
    border: 1px solid var(--border-colour);
    border-radius: 0.2rem;
    background-image: var(--button-background-image);
}

.button:focus {
    border-color: var(--button-border);
    outline: none;
}

.button:hover {
    cursor: pointer;
    background-image: var(--button-hover-background-image);
}

.button:disabled {
    color: var(--text-colour-mid);
    background-color: var(--background-base);
    background-image: none;
}

.button:disabled:hover {
    cursor: not-allowed;
}

.button img {
    margin: 0 auto;
}

.button:disabled img {
    opacity: 40%;
}

.navigationButton {
    font-family: var(--font-family-general);
    font-size: var(--font-size-normal);
    color: var(--navigation-button-colour);
    border: 1px solid var(--navigation-button-border);
    border-radius: 0.2rem;
    background-color: var(--navigation-button-background-base);
    transition: box-shadow 0.5s;
}

.navigationButton:hover {
    cursor: pointer;
    box-shadow: var(--navigation-button-shadow-colour) 0px 6px 12px 0px;
}

.input {
    font-family: var(--font-family-general);
    font-size: var(--font-size-normal);
    padding: 0.4rem;
    border: 1px solid var(--border-colour);
    background-color: var(--input-background);
    color: var(--input-text-colour);
}

.input::placeholder {
    color: var(--text-colour-placeholder);
    font-style: italic;
    font-size: var(--font-size-small);
}

.input:focus {
    outline: none;
}

.input___invalid {
    border-color: var(--border-colour-error);
    color: var(--text-colour-error);
}

.input___parameter {
    font-family: var(--font-parameters);
}

.selector {
    font-family: var(--font-family-general);
    font-size: var(--font-size-normal);
    background-color: var(--input-background);
    color: var(--input-text-colour);
    border: 1px solid var(--border-colour);
}

.link {
    color: var(--link-colour-not-visited);
    text-decoration: none;
}

.link:visited {
    color: var(--link-colour-visited);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.link:active {
    text-decoration: underline;
}