@import '../variables.css';

.CartItem {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 1px solid var(--border-colour);
    background-color: var(--item-background);
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.CartItem p {
    white-space: normal;
}

.CartItem>div {
    padding: 0.5rem 0;
}

.productDescription {
    width: 100%;
}

.productDescription p:not(:first-child) {
    font-size: var(--font-size-small);
}

.removeButtonImage {
    display: block;
    width: 25px;
    height: 25px;
}

.productAction {
    border-top: 1px solid var(--border-colour);
}

.productAction p:first-child {
    display: inline-block;
    border-radius: 1rem;
    margin-bottom: 0.3rem;
    padding: 0.1rem 0.5rem;
    background-color: limegreen;
    color: white;
}

.productAction p:not(:first-child) {
    font-size: var(--font-size-small);
}

.priceAndRemoveContainer {
    border-left: none;
    border-top: 1px solid var(--border-colour);
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    flex-wrap: wrap;
    align-items: center;
}

.error {
    color: var(--text-colour-error);
}

@media only screen and (min-width: 481px) {
    .removeButtonImage {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (min-width: 768px) {
    .CartItem {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border: 1px solid var(--border-colour);
        background-color: var(--item-background);
        padding: 0.5rem;
        margin-bottom: 1rem;
    }

    .CartItem>div {
        padding: 0 0.5rem;
    }

    .productDescription {
        width: 40%;
    }

    .productAction {
        width: 40%;
        border-left: 1px solid var(--border-colour);
        border-top: none;
    }

    .priceAndRemoveContainer {
        width: 20%;
        min-width: calc(4.5rem + 50px);
        border-left: 1px solid var(--border-colour);
        border-top: none;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        align-items: center;
    }
}