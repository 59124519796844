@import '../variables.css';

.tabListItem {
    display: inline-block;
    list-style: none;
    padding: 0.5rem 0.55rem;
    cursor: pointer;
    white-space: nowrap;
}

.tabListItem:hover {
    background-image: var(--item-hover-background-image);
    color: var(--item-highlight-colour);
}

.tabListItem___horizontal {
    margin: 10px 0 -1px 0;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
}

.tabListItem___vertical {
    margin: 0 -1px 0 10px;
}

.tabListItem___active {
    font-weight: bold;
    border: 1px solid var(--background-mid-accent);
    background-image: var(--item-highlight-background-image);
    color: var(--item-highlight-colour);
}

.tabListItem___active___horizontal {
    border-bottom-color: var(--background-mid-accent);
}

.tabListItem___active___vertical {
    border-right-color: var(--background-mid-accent);
}

.preventSelect {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}