.ParameterMappingSummary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px 5px;
    overflow-x: hidden;
}

.ParameterMappingSummary:hover {
    cursor: pointer;
}

.selected {
    color: rgb(255, 218, 9);
}

.disabled {
    background-color: rgba(255, 218, 9, 0.3);
}

.disabled:hover {
    color: rgb(255, 218, 9);
    background-color: inherit;
}

.name {
    white-space: normal;
}

.description {
    margin-left: 20px;
    white-space: normal;
    text-align: right;
}