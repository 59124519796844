@import './variables.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

html,
body {
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  font-family: var(--font-family-general);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-base);
}

img {
  display: block;
}

@media only screen and (min-width: 481px) {
  html {
    font-size: 22px;
  }
}