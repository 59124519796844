@import '../variables.css';

.operationList {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.selectOperationButton {
    padding: 0.4rem;
    margin: 0.4rem;
    color: var(--text-colour-normal);
    font-weight: bold;
    text-align: center;
    width: 100%;
    white-space: normal;
}