@import '../variables.css';

.ModalDialogue {
    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translateX(-50%) translateY(-50%);
    width: clamp(500px, 60%, 1000px);
    max-height: 80vh;
    background-color: var(--item-background);
    box-shadow: 10px 10px 10px var(--palette-dark);
    border-radius: 0.5rem;
    border: none;
    overflow: hidden;
}

/* The following ensures that the dialogue disappears when closed 
(because the style only applies when the dialogue is open). See
https://stackoverflow.com/questions/60365510/html5-dialog-element-close-button-not-working-properly */
.ModalDialogue[open] {
    display: flex;
    flex-direction: column;
}

.ModalDialogue::backdrop {
    background: rgba(0, 0, 0, 0.4);
}

.scrollArea {
    overflow-y: auto;
}

.contentContainer {
    margin: 1rem;
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.button {
    margin: 0.5rem 1rem 1rem 1rem;
    padding: 0.3rem 2rem;
}

.button___multiple {
    flex: 1 1 0;
}

@media only screen and (min-height: 850px) {
    .ModalDialogue {
        max-height: 65vh;
    }
}