@import '../variables.css';

.Nav {
    width: 100%;
    background-color: var(--background-mid);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
}

.list {
    color: #fff;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: stretch;
}

.listItem {
    white-space: nowrap;
    font-size: var(--font-size-normal);
    font-weight: bold;
    display: grid;
    place-items: stretch;
    grid-template-columns: 100%;
    text-align: center;
}

.link {
    padding: 0.5rem 0.7rem;
    display: block;
    color: var(--text-colour-navbar);
    text-decoration: none;
}

.link img {
    display: inline;
}

.listItem:hover,
.listItem:focus-within,
.listItem:hover a,
.listItem:focus-within a {
    color: var(--text-colour-navbar-highlight);
    background-color: var(--background-base);
    outline: none;
}

.listItem___selected,
.listItem___selected a {
    color: var(--text-colour-navbar-highlight);
    background-color: var(--background-base);
    outline: none;
}

@media only screen and (min-width: 481px) {
    .Nav {
        flex-direction: row;
    }

    .list {
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
    }

    .listItem {
        align-content: center;
        font-size: var(--font-size-small);
    }
}