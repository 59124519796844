.Missing {
    width: 100%;
    flex-grow: 1;
    padding: 1em;
    overflow-y: auto;
}

.container {
    padding: 4em;
}

.heading,
.text {
    margin-bottom: 1em;
    text-align: center;
}