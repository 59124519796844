@import '../../variables.css';

.Help {
    display: flex;
    align-items: flex-start;
    margin: -10px;
    width: calc(100% + 20px);
    overflow-y: hidden;
}

.outerContentContainer {
    width: 100%;
    max-height: calc(100vh - 102px);
    overflow: hidden;
}

.contentContainer {
    width: 100%;
    max-height: calc(100vh - 137px);
    overflow-x: hidden;
    overflow-y: auto;
}

.content {
    margin: 1rem;
    padding: 2rem;
    border: 1px solid var(--border-colour);
    background-color: var(--item-background);
}