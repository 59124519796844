@import '../../variables.css';

.HelpNode {
    font-size: var(--font-size-normal);
    width: 100%;
    white-space: pre-wrap;
}

.topicLinkContainer {
    margin-top: 0.5rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.navigationButton {
    text-align: left;
    margin: 0.3rem 0;
    padding: 0.5rem;
}

.topicNavigationContainer {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.topicNavigationButton {
    margin: 0.2rem 0;
    display: flex;
    align-items: center;
}

.hidden {
    visibility: hidden;
}

.topicNavigationCaption {
    font-size: var(--font-size-small);
    margin: 0 0.5rem;
}

.textLeft {
    text-align: left;
}

.textRight {
    text-align: right;
}

.alignRight {
    margin-left: auto;
}

.topicNavigationImage {
    display: block;
    margin: 0 auto;
    min-width: 40px;
    width: 40px;
    height: auto;
    fill: var(--navigation-button-colour);
}

.underConstructionContainer {
    margin: 2rem auto 0 auto;
}

.underConstruction {
    max-width: 100%;
    height: auto;
}

.imageAccreditation {
    margin: 0;
    font-size: var(--font-size-extra-small);
    word-wrap: break-word;
}

.HelpNode h1 {
    font-size: var(--font-size-extra-large);
    text-align: left;
    margin-bottom: 1rem;
}

.HelpNode h2 {
    font-size: var(--font-size-large);
    text-align: left;
}

.HelpNode section {
    margin: 1rem 0 2rem 0;
}

.HelpNode section:last-child {
    margin: 0;
}

.HelpNode section p {
    margin: 1rem 0;
}

.HelpNode section p:last-child {
    margin: 1rem 0 0 0;
}

.HelpNode section p:only-child {
    margin: 0;
}

.HelpNode ul {
    list-style-position: outside;
    margin-left: 1rem;
}

.HelpNode ul li {
    margin: 1rem 0;
    padding-left: 0.5rem;
}

.HelpNode ul li:last-child {
    margin: 0;
}

.HelpNode ol {
    list-style-position: outside;
    margin-left: 1rem;
}

.HelpNode ol li {
    margin: 1rem 0;
    padding-left: 0.5rem;
}

.HelpNode ol li:last-child {
    margin: 0;
}

.HelpNode ol ol {
    list-style-type: lower-alpha;
}

@media only screen and (min-width: 992px) {
    .HelpNode ul {
        margin-left: 2rem;
    }

    .HelpNode ol {
        margin-left: 2rem;
    }
}