@import '../variables.css';

.AccountManager {
    width: 100%;
}

.message {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.fieldContainer {
    border: 1px solid var(--border-colour);
    border-radius: 0.3rem;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--item-background);
}

.editorContainer {
    padding: 0.5rem 0;
}

.editorContainer:not(:last-child) {
    border-bottom: 1px solid var(--border-colour);
}

.editorContainer:last-child {
    padding: 0.5rem 0 0 0;
}

.editor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-flow: column nowrap;
    padding-bottom: 1rem;
}

.input {
    width: 100%;
}