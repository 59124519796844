@import '../../variables.css';

.SidebarItem:last-child {
    margin-bottom: 0;
}

.item___selected {
    background-image: var(--item-highlight-background-image);
    color: var(--item-highlight-colour);
}

.small {
    font-size: var(--font-size-small);
}

.navigationButton {
    width: 100%;
    text-align: left;
    margin-bottom: 0.5rem;
    padding: 0.3rem 0.9rem;
}

.toggleLabel::before {
    content: " ";
    display: inline-block;
    color: var(--text-colour-normal);
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid currentColor;
    vertical-align: middle;
    margin-right: 0.7rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out, color 0.2s ease-out;
}

.item___selected .toggleLabel::before {
    color: var(--item-highlight-colour);
}

.toggleLabel {
    display: block;
    font-weight: bold;
    text-align: left;
    white-space: normal;
    cursor: pointer;
    transition: all 0.2s ease-out;
}

.contentContainer {
    margin-left: 1.2rem;
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height 0.25s ease-in-out;
}

.contentContainer___expanded {
    max-height: 1000px;
}

.expanded .toggleLabel::before {
    transform: rotate(90deg) translateX(-3px);
}