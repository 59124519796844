@import '../variables.css';

.InputWithHint {
    width: 100%;
}

.input {
    width: 100%;
}

.hintContainer {
    margin-top: 2px;
    font-family: var(--font-family-general);
    font-size: var(--font-size-extra-small);
    font-style: italic;
    margin-left: 0.5rem;
}

.hintContainer ul {
    list-style-position: outside;
}

.hintContainer li {
    margin-left: 1rem;
}