@import '../../variables.css';

.banner {
    height: 300px;
    background-image: url("../../static/img/hero-960x540.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 2rem;
}

.lastUpdated {
    font-weight: bold;
}