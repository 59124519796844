@import '../variables.css';

.FieldEditor {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: flex-start;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.value {
   width: 100%; 
}

.fieldName {
    font-weight: bold;
    display: block;
}

.fieldValue {
    font-size: var(--font-size-large);
    margin: 6px 0;
}

.editor {
    width: 100%;
}

.errorMessage {
    color: var(--text-colour-error);
    font-size: var(--font-size-small);
}

.buttonImage {
    display: block;
    width: 25px;
    height: 25px;
}

.button {
    margin-left: 0.5rem;
}

.okCancelContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media only screen and (min-width: 481px) {
    .buttonImage {
        width: 50px;
        height: 50px;
    }
}