.PerformanceCategoryContainer {
    width: 100%;
    overflow-x: hidden;
}

.subHeading {
    text-align: center;
    white-space: normal;
    margin: 1.5rem 0;
}

.categoryList {
    width: min(100%, 700px);
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1rem;
    flex-flow: row wrap;
}
