@import '../variables.css';

.Carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}

.navButton {
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    transition: opacity 0.5s;
    opacity: 50%;
    background-color: var(--button-background-base);
}

.navButton:hover {
    transition: opacity 0.5s;
    opacity: 100%;
    cursor: pointer;
}

.navButton___previous {
    top: calc(50% - 25px);
    left: 0;
}

.navButton___next {
    top: calc(50% - 25px);
    right: 0;
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
}