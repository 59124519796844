@import '../variables.css';

.Cart {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 auto;
}

.title {
    margin: 1rem;
}

.message {
    font-size: var(--font-size-extra-large);
    white-space: normal;
}

.centre {
    text-align: center;
}

.itemContainer {
    width: min(800px, 90%);
    margin: 1rem auto;
}

.hidden {
    display: none;
}

.itemList {
    list-style-type: none;
}

.backButtonImage {
    display: block;
    width: 25px;
    height: 25px;
}

.backButton {
    position: absolute;
    top: 0.5rem;
}

.checkoutDetail {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
}

.amountContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.checkoutButton {
    padding: 0.3rem 2rem;
}

.statusMessage {
    font-size: var(--font-size-normal);
    margin-top: 1rem;
}

.error {
    color: var(--text-colour-error);
}

@media only screen and (min-width: 481px) {
    .backButtonImage {
        width: 50px;
        height: 50px;
    }
}

@media only screen and (min-width: 768px) {
    .itemContainer {
        width: 90%;
        margin: 1rem auto;
    }
}