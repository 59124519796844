@import '../variables.css';

.Ops {
    margin: 1rem auto;
    width: min(800px, 90%);
}

.feedbackHeading {
    text-align: center;
    margin: 1rem 0;
}