@import '../variables.css';

.ExpiryBar {
    width: 100%;
    border: 1px solid var(--border-colour);
    border-radius: 0.5rem;
    background-color: var(--background-base-semi-opaque);
}

.bar {
    height: 0.5rem;
    border-radius: 0.5rem;
}

.danger {
    background-color: var(--colour-danger);
}

.warning {
    background-color: var(--colour-warning);
}

.safe {
    background-color: var(--colour-safe);
}