.Layout {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}

.sampleAircraft {
    background-image: none;
}

.licence {
    background-image: none;
}

.apron {
    background-image: none;
}

.hangar {
    background-image: none;
}

.leasing {
    background-image: none;
}

.ops {
    background-image: none;
}

.rules {
    background-image: none;
}

.admin {
    background-image: none;
}

.unauthorised {
    background-image: none;
}

.missing {
    background-image: none;
}

@media only screen and (min-width: 992px) {
    .sampleAircraft {
        background-image: url("../static/img/sample-aircraft-1018x1018.png");
    }

    .licence {
        background-image: url("../static/img/licence-915x994.png");
    }

    .apron {
        /* By Timo Breidenstein - http: //www.airliners.net/photo/Gulfstream-Aerospace-G-IV/2393432/L/, GFDL 1.2, https://commons.wikimedia.org/w/index.php?curid=37249989 */
        background-image: url("../static/img/apron-1280x933.png");
    }

    .hangar {
        background-image: url("../static/img/hangar-1200x1200.png");
    }

    .leasing {
        background-image: url("../static/img/chrome-propeller-1702x1287.png");
    }

    .ops {
        background-image: url("../static/img/mainframes-1920x1097.png");
    }

    .rules {
        /* Image by <a href="https://www.freepik.com/free-photo/beautiful-arrangement-different-books_12892751.htm#page=4&query=book%20side%20view&position=18&from_view=keyword&track=ais">Freepik</a> */
        background-image: url("../static/img/rule-books-1076x715.png");
    }

    .admin {
        background-image: url("../static/img/zeus-1280x1280.png");
    }

    .unauthorised {
        background-image: url("../static/img/not-authorised-842x1296.png");
        background-size: contain;
    }

    .missing {
        background-image: url("../static/img/helicopter-1151x795.png");
    }
}