@import '../variables.css';

.form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
}

.formInputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-flow: column nowrap;
    padding: 1rem 0;
}

.button {
    width: auto;
    padding: 0.3rem 2rem;
    margin: 1rem 0 1rem auto;
}

.status {
    color: var(--text-colour-normal);
    padding: 0;
    margin-top: 1rem;
}

.status___error {
    color: var(--text-colour-error);
}

@media only screen and (min-width: 481px) {
    .form {
        flex-flow: row nowrap;
        align-items: flex-start;
        margin-top: 1rem;
    }

    .formInputs {
        width: 70%;
        padding: 1rem 1rem 1rem 0;
        border-right: 1px solid var(--border-colour);
    }

    .button {
        width: 30%;
        padding: 0.3rem;
        margin: 1rem 0 1rem 1rem;
    }
}

/* See https://stackoverflow.com/questions/12539006/tooltips-for-mobile-browsers
   May be able to move this to a globally available location in order to benefit
   anything with a title.  TODO.

   Also see https://www.smashingmagazine.com/2022/03/guide-hover-pointer-media-queries/
   for a good explanation of this stuff.

   But WATCH OUT: it is an indiscriminate technique with knock-on effects!
 */
/* @media (any-pointer: coarse),
(hover: none) {
    [title] {
        position: relative;
        display: flex;
        justify-content: center;
    }

    [title]:focus::after {
        content: attr(title);
        position: absolute;
        top: 90%;
        color: #000;
        background-color: #fff;
        border: 1px solid;
        width: fit-content;
        padding: 3px;
    }
} */