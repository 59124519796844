@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Manrope:wght@300&family=Nanum+Gothic&family=PT+Sans&family=Quicksand&family=Ubuntu:wght@300&display=swap");

:root {
    /* Colour palette */
    --palette-light: #F0F4EF;
    --palette-light-semi-opaque: #F0F4EF60;
    --palette-mid-light: #B8BDBC;
    --palette-mid-light-semi-opaque: #B8BDBC70;
    --palette-mid: #7F8688;
    --palette-mid-dark: #464F55;
    --palette-dark: #0D1821;
    --palette-dark-semi-opaque: #0D182160;

    --palette-colour-1: var(--palette-light);
    --palette-colour-2: var(--palette-mid-light);
    --palette-colour-3: var(--palette-mid);
    --palette-colour-4: var(--palette-mid-dark);
    --palette-colour-5: var(--palette-dark);
    --palette-colour-6: #f4b31abb;
    --palette-colour-7: orangered;
    --palette-colour-8: white;
    --palette-colour-9: rgba(9, 34, 64, 0.267);
    --palette-colour-10: rgb(20, 64, 85);
    --palette-colour-11: rgb(9, 34, 64);
    --palette-colour-12: rgb(3, 20, 70);
    --palette-colour-13: #f4b41a;
    --palette-colour-14: #143d59;
    --palette-colour-15: var(--palette-dark-semi-opaque);
    --palette-colour-16: blue;
    --palette-colour-17: purple;
    --palette-colour-18: rgba(96, 158, 255, 0.267);
    --palette-colour-19: rgb(106, 190, 255);
    --palette-colour-20: rgb(96, 158, 255);
    --palette-colour-21: rgb(51, 120, 238);
    --palette-colour-22: limegreen;
    --palette-colour-23: var(--palette-light-semi-opaque);
    --palette-colour-24: #0a1c28;
    --palette-colour-25: #f4d31a;
    --palette-colour-26: var(--palette-mid-light-semi-opaque);

    /* Colours */
    --background-base: var(--palette-colour-1);
    --background-base-semi-opaque: var(--palette-colour-23);
    --background-mid-base: var(--palette-colour-2);
    --background-mid: var(--palette-colour-3);
    --background-mid-accent: var(--palette-colour-4);
    --background-accent: var(--palette-colour-5);
    --background-accent-semi-opaque: var(--palette-colour-15);

    --colour-danger: var(--palette-colour-7);
    --colour-warning: var(--palette-colour-6);
    --colour-safe: var(--palette-colour-22);

    --text-colour-placeholder: var(--palette-colour-6);
    --text-colour-normal: var(--palette-colour-5);
    --text-colour-mid: var(--palette-colour-4);
    --text-colour-inverted: var(--palette-colour-1);
    --text-colour-error: var(--palette-colour-7);
    --text-colour-navbar: var(--palette-colour-13);
    --text-colour-navbar-highlight: var(--palette-colour-14);
    --text-colour-application-title: var(--palette-colour-14);
    
    --border-colour: var(--palette-colour-3);
    --border-colour-error: var(--palette-colour-7);

    --input-background: var(--palette-colour-14);
    --input-text-colour: var(--palette-colour-13);

    --cart-border-colour: var(--palette-colour-14);
    --cart-fill-colour: var(--palette-colour-13);
    --cart-hover-border-colour: var(--palette-colour-24);
    --cart-hover-fill-colour: var(--palette-colour-25);

    --button-border: var(--palette-colour-21);
    --button-background-base: var(--palette-colour-20);
    --button-background-image: linear-gradient(var(--palette-colour-18), var(--palette-colour-20));
    --button-hover-background-image: linear-gradient(var(--palette-colour-18), var(--palette-colour-19));

    --navigation-button-colour: var(--palette-colour-21);
    --navigation-button-border: var(--palette-colour-26);
    --navigation-button-background-base: var(--palette-colour-8);
    --navigation-button-shadow-colour: var(--palette-colour-15);

    --item-background: var(--palette-colour-8);
    --item-highlight-colour: var(--palette-colour-8);
    --item-highlight-border: var(--palette-colour-12);
    --item-highlight-background-image: linear-gradient(var(--palette-colour-9), var(--palette-colour-11));
    --item-hover-background-image: linear-gradient(var(--palette-colour-9), var(--palette-colour-10));

    --logo-colour-light: var(--palette-colour-13);
    --logo-colour-dark: var(--palette-colour-14);

    --link-colour-not-visited: var(--palette-colour-16);
    --link-colour-visited: var(--palette-colour-17);

    /* Font options */
    --font-family-1: "PT Sans", sans-serif;
    --font-family-2: "Nanum Gothic", sans-serif;
    --font-family-3: monospace;
    --font-size-extra-small: 0.7rem;
    --font-size-small: 0.8rem;
    --font-size-normal: 1rem;
    --font-size-large: 1.2rem;
    --font-size-extra-large: 1.5rem;
    --font-size-max: 2rem;

    /* Fonts */
    --font-family-general: var(--font-family-1);
    --font-family-landing-page: var(--font-family-2);
    --font-parameters: var(--font-family-3);
}

/* @media (prefers-color-scheme: dark) {
    :root {
        --palette-colour-1: var(--palette-dark);
        --palette-colour-2: var(--palette-mid-dark);
        --palette-colour-3: var(--palette-mid);
        --palette-colour-4: var(--palette-mid-light);
        --palette-colour-5: var(--palette-light);
    }
} */