@import '../variables.css';

.Verify {
    width: min(800px, 90%);
    margin: 3rem auto;
    text-align: center;
    font-size: var(--font-size-large);
}

.Verify p {
    margin-top: 1rem;
}