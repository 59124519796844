@import '../variables.css';

.Tabs___vertical {
    display: flex;
}

.Tabs___horizontal {
    width: 100%;
}

.tabList {
    display: flex;
}

.tabList___horizontal {
    padding: 0 20px;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid var(--background-mid-accent);
}

.tabList___vertical {
    padding: 20px 0;
    flex-flow: column nowrap;
    border-right: 1px solid;
    margin-block-start: 0.65em;
    margin-block-end: 0;
    width: 100%;
}

.tabContent___horizontal {
    padding-top: 1rem;
}

.tabContent___vertical {
    flex-grow: 1;
    padding-left: 1rem;
}