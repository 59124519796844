@import '../../variables.css';

.BreadcrumbBar {
    font-size: var(--font-size-small);
    max-height: 6rem;
    background-color: var(--background-mid);
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s;
    padding: 0.3rem 1rem;
}

.BreadcrumbBar ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
}

.BreadcrumbBar li:not(:first-child)::before {
    content: "→";
    color: var(--text-colour-navbar);
    margin: 0 0.5rem;
}

.hidden {
    max-height: 0;
    padding: 0;
    transition: max-height 0.1s, padding 0.1s;
}

.link {
    color: var(--text-colour-navbar);
    font-weight: bold;
    text-decoration: none;
}