.OperationDetail {
    width: min(100%, 700px);
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    flex-flow: column nowrap;
}

.operationNote {
    font-size: 0.9rem;
    margin-top: 0.2rem;
    margin-left: 1rem;
    padding-left: 0.4rem;
    white-space: normal;
}