@import '../variables.css';

.InputParameterSequencer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    flex-flow: column nowrap;
}

.parameterContainer {
    min-height: 5.5rem;
    padding-top: 0.5rem;
}

.buttonContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;
}

.navButton {
    border-radius: 12.5px;
    width: max(50px, 15%);
}

.navButton:disabled .navImage {
    fill: var(--background-mid);
}

.navImage {
    display: block;
    margin: 0 auto;
    width: 25px;
    height: 25px;
}

.list {
    list-style-type: none;
}

.parameterSummaryItem {
    margin: 2px 0;
    border: 1px solid var(--border-colour);
    border-radius: 0.1rem;
}

.parameterSummaryItem:hover {
    background-image: var(--item-hover-background-image);
    color: var(--item-highlight-colour);
}

.parameterSummaryItemSelector {
    display: none;
}

.parameterSummaryItem___selected {
    background-image: var(--item-highlight-background-image);
    color: var(--item-highlight-colour);
}

@media only screen and (min-width: 481px) {
    .navButton {
        border-radius: 25px;
        width: max(100px, 15%);
    }

    .navImage {
        width: 50px;
        height: 50px;
    }
}