@import '../../variables.css';

.RootItem {
    margin: 1rem 0.5rem;
    width: 40%;
    min-width: 5rem;
    white-space: normal;
    word-wrap: break-word;
}

.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
}

.image {
    padding-bottom: 0.5rem;
    width: 64px;
    height: 64px;
}

.title {
    width: 100%;
    border-top: 1px solid var(--border-colour);
    vertical-align: text-top;
    padding-top: 1rem;
}

@media only screen and (min-width: 481px) {
    .RootItem {
        min-width: 10rem;
    }
}