@import '../variables.css';

.CartIcon {
    display: inline-block;
    position: relative;
}

.CartIcon:hover {
    cursor: pointer;
}

.CartIcon:hover .path {
    stroke: var(--cart-hover-border-colour);
    fill: var(--cart-hover-fill-colour);
}

.countContainer {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid white;
    background-color: red;
    color: white;
    font-size: 0.7rem;
    font-weight: bold;
    border-radius: 0.7rem;
    padding: 0.1rem 0.4rem;
}

.path {
    stroke: var(--cart-border-colour);
    fill: var(--cart-fill-colour);
}