@import '../variables.css';

.AircraftSummary {
    margin: 1rem;
    width: 16rem;
    border: 1px solid var(--border-colour);
    border-radius: 0.6rem;
    box-shadow: 8px 8px 20px var(--background-mid-accent);
    text-align: center;
    white-space: normal;
}

.aircraftLink {
    width: 100%;
    display: grid;
    grid-template-columns: 40px auto 40px;
    border-radius: 0.6rem;
    text-decoration: none;
    color: var(--text-colour-normal);
    background-image: none;
    background-color: var(--item-background);
    font-size: var(--font-size-normal);
    transition: color 0.2s ease-out;
}

.AircraftSummary:hover,
.AircraftSummary:focus,
.AircraftSummary___selected {
    border-color: var(--item-highlight-border);
}

.aircraftLink:hover .description {
    border-color: var(--item-highlight-colour);
}

.aircraftLink:focus {
    border-color: var(--item-highlight-border);
}

.aircraftLink___selected.aircraftLink___selected {
    background-image: var(--item-highlight-background-image);
    color: var(--item-highlight-colour);
}

.aircraftLink___selected .description {
    border-color: var(--item-highlight-colour);
}

.aircraftLink.aircraftLink:hover {
    background-image: var(--item-hover-background-image);
    color: var(--item-highlight-colour);
}

.aircraftLink.aircraftLink:hover .path {
    fill: var(--text-colour-inverted);
}

.manufacturer {
    grid-column: 2;
    font-size: var(--font-size-small);
    padding: 0.4rem;
}

.model {
    grid-column: 2;
    font-size: var(--font-size-max);
    font-weight: bold;
    padding: 0.1rem;
}

.hangarImage {
    grid-column: 3;
    align-self: center;
}

.description {
    grid-column-start: 1;
    grid-column-end: 4;
    border-top: 1px solid var(--border-colour);
    vertical-align: text-top;
    padding: 1.5rem 0.5rem;
}

.description___withExpiry {
    padding: 0.9rem 0.5rem 0 0.5rem;
}

.expiryBarContainer {
    grid-column-start: 1;
    grid-column-end: 4;
    margin: 0.5rem 1rem 1rem 1rem;
}

.path {
    fill: var(--text-colour-normal);
    transition: fill 0.2s ease-out;
}

.path___selected {
    fill: var(--text-colour-inverted);
}