@import '../variables.css';

.PerformanceDataDisclaimer p {
    padding: 0.5rem 0;
}

.PerformanceDataDisclaimer h2 {
    text-align: center;
    padding: 0 0 1rem 0;
}

.PerformanceDataDisclaimer ol {
    list-style-position: outside;
    font-size: var(--font-size-small);
}

.PerformanceDataDisclaimer li {
    margin-left: 1em;
    padding-left: 1em;
}

.PerformanceDataDisclaimer ol ol {
    list-style-type: lower-alpha;
}