@import '../variables.css';

.App {
    min-height: 100vh;
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--background-mid-base);
    box-shadow: 0px 0px 15px var(--background-mid);
    background-color: var(--background-base);
    color: var(--text-colour-normal);
}

@media only screen and ((min-width: 481px) and (min-height: 850px)) {
    .App {
        height: 100vh;
    }
}