@import '../variables.css';

.title {
    text-align: center;
    white-space: normal;
    margin: 1rem 0;
}

.searchBarContainer {
    margin-bottom: 1rem;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: normal;
    margin: 3rem 0;
}

.message {
    font-size: var(--font-size-extra-large);
}

.subMessage {
    font-size: var(--font-size-normal);
    margin-top: 0.5rem;
}