@import '../variables.css';

.Home {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
    padding: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.Home p {
    padding: 1rem 0;
}

.banner {
    font-size: 1.3rem;
    background-image: url("../static/img/perf-planning-1161x778.png");
    background-repeat: repeat;
    background-size: cover;
    text-shadow: 1px 1px 0 var(--background-accent);
}

.title {
    font-family: var(--font-family-landing-page);
    font-weight: bold;
    font-size: 2rem;
    color: var(--text-colour-application-title);
    margin: 2rem 0 3rem 0;
}

.article {
    padding: 1rem;
}

.inverted {
    background-color: var(--background-accent-semi-opaque);
    color: var(--text-colour-inverted);
}

.nowrap {
    white-space: normal;
}

.features {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.feature {
    width: 100%;
    padding: 2rem 5%;
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
    text-align: left;
    margin: 0;
    background-color: rgb(225, 236, 233, 0.7);
}

.feature:nth-child(even) {
    background-color: rgba(83, 98, 94, 0.7);
    color: white;
}

.feature:nth-child(even) .articleText {
    order: 2;
}

.icon {
    display: none;
}

.feature:nth-child(even) .icon {
    fill: white;
}

.articleText {
    width: 100%;
    font-size: 1rem;
}

.articleText ul {
    list-style: none;
    margin-top: 1rem;
}

.articleText li {
    padding: 0 0 0.4rem 2.5rem;
    margin: 0.4rem 0;
    background: url("../static/img/tick-green-24x24.png") no-repeat top left;
}

.quotes {
    width: 100%;
    text-align: center;
    padding: 1.5rem 1rem;
    background-color: rgba(83, 98, 94, 0.7);
}

.quotes ul {
    list-style: none;
    margin: 1rem 0;
}

.quotes li {
    display: inline;
    margin: 0 0.5rem;
    color: var(--logo-colour-dark);
}

.quotes li:nth-child(even) {
    color: var(--logo-colour-light);
}

.quoteMark {
    color: var(--background-accent);
}

.rule {
    width: 100%;
    margin: 1rem 0;
    border: 0;
}

/* For the home page only it appears that styles are not being applied in the order 
   they are declared on a button.  The below selector increases the specificity so 
   as to overcome the problem.
*/
.startButton.startButton {
    font-size: var(--font-size-large);
    padding: 0.8rem 2rem;
    margin: 0.5rem;
    border-radius: 0.3rem;
}

@media only screen and (min-width: 481px) {
    .title {
        font-size: 2.5rem;
    }
    
    .feature {
        padding: 2rem 10%;
    }

    .articleText li {
        padding: 0.4rem 0 0.4rem 3rem;
        background: url("../static/img/tick-green-48x48.png") no-repeat top left;
    }

    .nowrap {
        white-space: nowrap;
    }
}

@media only screen and (min-width: 768px) {
    .title {
        font-size: 3rem;
        margin: 4rem 0 5rem 0;
    }
    
    .articleText {
        width: 70%;
    }

    .icon {
        display: inline;
        margin-left: 1rem;
        height: 100px;
        width: 100px;
    }

    .feature:nth-child(even) .icon {
        margin-right: 1rem;
    }
}

@media only screen and (min-width: 992px) {
    .icon {
        height: 160px;
        width: 160px;
    }

    .quotes {
        padding: 2rem 3rem;
    }
}