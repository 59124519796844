@import '../variables.css';

.form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
}

.formInputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-flow: column nowrap;
    padding: 1rem 0;
}

.button {
    width: auto;
    padding: 0.3rem 2rem;
    margin: 1rem 0 1rem auto;
}

.status {
    color: var(--text-colour-normal);
    padding: 0;
    margin-top: 1rem;
}

.status___error {
    color: var(--text-colour-error);
}

.forgottenPasswordContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.checkbox {
    width: 1rem;
    height: 1rem;
    margin: 0;
}

.paddedLabel {
    margin: 0 0 0 0.5rem;
    text-align: left;
}

.errmsg {
    color: var(--text-colour-error);
    padding: 0;
    margin-top: 1rem;
}

@media only screen and (min-width: 481px) {
    .form {
        flex-flow: row nowrap;
        align-items: flex-start;
        margin-top: 1rem;
    }

    .formInputs {
        width: 70%;
        padding: 1rem 1rem 1rem 0;
        border-right: 1px solid var(--border-colour);
    }

    .button {
        width: 30%;
        padding: 0.3rem;
        margin: 1rem 0 1rem 1rem;
    }
}