@import '../../variables.css';

.Sidebar {
    width: max(35%, 300px);
    padding: 0.5rem;
    height: calc(100vh - 102px);
    border-right: 1px solid var(--border-colour);
    background-color: var(--item-background);
    overflow-x: hidden;
    overflow-y: auto;
    display: none;
}

@media only screen and (min-width: 768px) {
    .Sidebar {
        display: block;
    }
}