.Admin {
    width: 100%;
    padding: 1rem;
    overflow-x: hidden;
}

.title {
    text-align: center;
    white-space: normal;
    margin: 1rem 0;
}

.contentContainer {
    width: min(800px, 100%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.fieldLabel {
    display: block;
    margin: 0;
    text-align: left;
    font-size: 1rem;
    white-space: normal;
}

.selector {
    padding: 0.4rem 0.2rem;
    width: 100%;
    text-align: left;
    margin: 3.5px 0;
}

.statusMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.responseMessage {
    font-size: var(--font-size-small);
}