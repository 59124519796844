@import '../variables.css';

.Operation {
    position: relative;
    width: 100%;
}

.backButton {
    position: absolute;
    top: 0;
}

.resetButton {
    position: absolute;
    top: 0;
    right: 0;
}

.categoryHeading {
    width: 70%;
    text-align: center;
    white-space: normal;
    margin: 1rem auto 0 auto;
}

.operationHeading {
    width: 70%;
    text-align: center;
    white-space: normal;
    margin: 0 auto 2rem auto;
}

.bannerContainer {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid var(--border-colour);
}

.promptContent {
    white-space: normal;
}

.operationImage {
    display: block;
    width: 25px;
    height: 25px;
}

@media only screen and (min-width: 481px) {
    .operationImage {
        width: 50px;
        height: 50px;
    }
}