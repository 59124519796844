@import '../variables.css';

.Footer {
    font-size: var(--font-size-small);
    background-color: var(--background-mid);
    padding: 0.5rem;
    display: grid;
    place-content: center;
    text-align: center;
    color: var(--logo-colour-light);
}

.links {
    list-style-type: none;
}

.links li {
    display: inline;
}

.links li:not(:last-child)::after {
    white-space: pre;
    content: "   |   ";
    color: var(--logo-colour-dark);
}

.link {
    color: var(--logo-colour-light);
    text-decoration: underline;
    cursor: pointer;
}

.buttonLink {
    color: var(--logo-colour-light);
    background-color: transparent;
    font-family: var(--font-family-general);
    font-size: var(--font-size-small);
    text-decoration: underline;
    cursor: pointer;
    border: none;
}