@import '../variables.css';

.bannerContainer {
    width: 100%;
    padding: 75px 0;
    background-color: black;
    background-image: url("../static/img/slinsgby.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: var(--palette-mid-light);
}